interface Drill {
    _id: string;
    title: string;
    topic: string;
  }
  
  export const drillsData: { [category: string]: Drill[] } = {
    'הטעיות': [
      { _id: 'a100', title: 'הטעיית קרוס-אובר', topic: 'הטעיות' },
      { _id: 'a101', title: 'אין אאוט', topic: 'הטעיות' },
      { _id: 'a102', title: 'הטעיית השהייה', topic: 'הטעיות' },
      { _id: 'a103', title: 'גב סטפ', topic: 'הטעיות' },
      { _id: 'a104', title: "בונוס: תרגול סיומות", topic: 'הטעיות' },
    ],
    'חדירות': [
      { _id: 'a110', title: 'הרחקת המגן בחדירה', topic: 'חדירות' },
      { _id: 'a111', title: 'חדירה מול מגן גבוה', topic: 'חדירות' },
      { _id: 'a112', title: 'באמפ יורו סטפ', topic: 'חדירות' },
      { _id: 'a113', title: 'יורו סטפ מהמקום', topic: 'חדירות' },
      { _id: 'a114', title: 'בונוס: הגנה נגד חדירה', topic: 'חדירות' },
    ],
    'כדרור': [
      { _id: 'a124', title: 'כדרור מסל לסל', topic: 'כדרור' },
      { _id: 'a120', title: 'כדרור תחת לחץ', topic: 'כדרור' },
      { _id: 'a121', title: 'כדרור לחץ מגרש שלם', topic: 'כדרור' },
      { _id: 'a122', title: 'כדרור בפיק אנד רול', topic: 'כדרור' },
      { _id: 'a123', title: 'בונוס: שליטה בכדור', topic: 'כדרור' },
    ],
    'קליעה': [
      { _id: 'a130', title: 'הכנה לקליעה', topic: 'קליעה' },
      { _id: 'a131', title: 'טקס זריקת עונשין', topic: 'קליעה' },
      { _id: 'a132', title: 'סטפ-בק', topic: 'קליעה' },
      { _id: 'a133', title: 'סטפ-בק ארוך', topic: 'קליעה' },
      { _id: 'a134', title: 'בונוס: תיקון החטאות', topic: 'קליעה' },
    ],
    'פוסט אפ': [
      { _id: 'a140', title: 'באמפים בפוסט-אפ', topic: 'פוסט אפ' },
      { _id: 'a141', title: 'סבסוב בפוסט-אפ', topic: 'פוסט אפ' },
      { _id: 'a142', title: 'צעד וחצי בפוסט-אפ', topic: 'פוסט אפ' },
      { _id: 'a143', title: 'בונוס: הגנת פוסט אפ', topic: 'פוסט אפ' },
    ],
  };
  