
import React from 'react';
import gifHero from '../../assets/images/open-screen.gif';

export function HeroLogin() {
    return (
        <section className="video-hero2">
            <img src={gifHero} alt="" />
        </section>
    )
}